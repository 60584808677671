import {Header} from "../components/header";
import * as React from "react";
import {Footer} from "../components/landingpage/footer";
import desktopipadmobile from "../img/desktopipadmobile.jpeg";
import {MetaTags} from "../components/metaTags";
import {Link} from "gatsby";

const title = "Vos projets avec une haute qualité de code | WealCome";
const metaDescription = "Chez WealCome, tous vos projets sont réalisés avec" +
    " les meilleures méthodes de programmation dont TDD, DDD et la Clean Architecture.";

export default () => {
    return <div>
        <MetaTags title={title} description={metaDescription}/>
        <Header fixedTop={true} backgroundTransition={false}/>
        <section className="ptb-100">
            <div className="main container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-8">
                        <div className="section-heading text-center mb-5">
                            <h1>Réalisations de vos projets <br/>de A à Z</h1>
                            <p className="lead">
                                La <strong>haute qualité de code</strong> est
                                la seule <strong>vraie condition</strong> pour ne pas exploser le budget de
                                vos projets ; mais au contraire le <strong>réduire</strong>.
                            </p>
                            <p className="lead">
                                Nous concevons et réalisons vos projets dans les <strong>règles de
                                l'art</strong> mettant en oeuvre parfaitement tous les *DD que
                                sont <strong>TDD</strong>, <strong>BDD</strong>, <strong>ATDD</strong>, <strong>DDD</strong> ainsi
                                que
                                la <strong>Clean Architecture</strong>.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <img src={desktopipadmobile} alt="Réalisations de tout projet"
                                     className="img-fluid" style={{borderRadius: 15}}/>
                            </div>
                        </div>
                        <br/>
                        <h3>Tout type de projet</h3>
                        <p><strong>Notre expertise est approfondie</strong> et permet de <strong>réaliser à très haut
                            niveau</strong> des <strong>applications mobiles</strong>,
                            des <strong>applications Web</strong>, des <strong>backends fortement scalables</strong> et
                            des <strong>clients lourds</strong>.</p>
                        <h3>Un mode forfait intelligent avec obligation de résultats</h3>
                        <p>Nous aimons <strong>coupler le mode forfait et l'Agilité</strong>.</p>
                        <p>En régie, il est fréquent que le Client tombe à cours de ressource
                            avant même d'avoir pu palper le livrable attendu.</p>
                        <p>Conscient de cette problématique,
                            WealCome propose d'<strong>associer à tout budget une obligation de résultat</strong>,
                            et <strong>non seulement de moyens</strong>.
                        </p>
                        <p>Tout Client de WealCome peut alors <strong>prévoir avec précision le budget
                            nécessaire</strong> à une fonctionnalité logicielle exigée ; <strong>sans mauvaise
                            surprise</strong>.</p>
                        <p>Nous parlons d'Agilité au forfait par le fait que nos devis pour un même Client sont
                            nombreux,
                            cohérents et extrêment fins ; permettant le <strong>mindset agile</strong>.
                        </p>

                        <h3>La fameuse garantie 0 Bug</h3>
                        <p>Nos compétences nous permettent de vous <strong>garantir l'abscence
                            d'anomalies algorithmiques à chaque mise en production</strong> ;
                            y compris la plus infime des anomalies.</p>
                        <p>Cette <strong>garantie est gravée sur votre devis</strong>, quelqu'il soit.</p>
                        <p><strong>Nous aimons satisfaire nos clients</strong> et nous vous devons <strong>le
                            meilleur
                            de
                            la qualité logicielle sans contrainte</strong>.
                        </p>
                        <h3>Une très haute qualité de code et de conception</h3>
                        <p>Nous maîtrisons les <strong>pratiques les plus avancées de conception
                            logicielle</strong> telles
                            que :</p>
                        <ul style={{listStyleType: "disc", paddingLeft: 40, marginBottom: 20}}>
                            <li>Le <strong>Test-Driven Development </strong>(Pratique intensive et ultra productive
                                -
                                <strong>100% de code coverage</strong>)
                            </li>
                            <li>Le <strong>Domain-Driven Design</strong></li>
                            <li>Le <strong>CQRS (Command Query Responsibility Segregation)</strong></li>
                            <li>Les <strong>Design Patterns</strong> sans exception</li>
                            <li>Les <strong>techniques d'abstractions et de découplages</strong></li>
                            <li>Les <strong>techniques approfondies de refactoring</strong></li>
                            <li>Les <strong>rédactions de tests unitaires, tests d'acceptation, tests d'intégration
                                et
                                tests end-to-end</strong></li>
                        </ul>
                        <p>Voir <Link to="/blog">notre blog</Link> pour découvrir nos pratiques de code et
                            recommandations.
                        </p>
                        <h3>Un accompagnement complet dans l'expression du besoin</h3>
                        <p>Des specs de centaines de pages avant d'entamer la première ligne de code ?
                            Pas de ça chez nous.</p>
                        <p>En effet, cette façon de faire ne fonctionne pas et bride fortement le client
                            dans ces futures décisions de valeur.
                        </p>
                        <p>Nous proposons à nos clients de les <strong>accompagner dès le premier instant dans
                            l'art de
                            l'expression du besoin</strong>.</p>
                        <p>Notre arme ? Des <strong>ateliers BDD alias Behaviour-Driven
                            Development intelligents</strong>.
                        </p>
                        <p>Le but de ces ateliers est de <strong>faire face aux éventuels quiproquo et subtiles
                            ambiguïtés</strong> pouvant amener le projet à sa perte.<br/>
                            Cela consiste à découvrir
                            ensemble des exemples de scénarios de comportements logiciels à partir des règles de
                            gestion
                            de l'application.
                        </p>
                        <p>Cette solution est ultra-efficace et <strong>100% en phase avec l'Agilité</strong>.</p>
                        <h3>L'agilité au coeur du process !</h3>
                        <p><strong>Changer d'avis doit être permis !</strong></p>
                        <p>Notre mode Forfait ne serait pas qualifié d'intelligent s'il ne se prêtait pas
                            à vos changements d'idées, d'avis et de directions.</p>
                        <p>Même après avoir signé un de nos devis, nous vous <strong>laissons une très acceptable
                            marge de manoeuvre pour rectifier, corriger, ajouter ou retirer
                            des idées/fonctionnalités</strong>, <strong>même si cela n'a pas été spécifié dans le
                            devis
                            signé !</strong></p>
                        <p>Ceci est entièrement vu avec le Client lors du rendez-vous
                            afin de lui exposer tous ses droits.<br/>
                            Agilité oblige, <strong>nos devis sont assez fins pour permettre
                                des livraisons continuelles et feedbacks fréquents</strong>, afin que vous vous
                            sentiez
                            complètement <strong>actifs</strong> à tout moment de la vie du projet.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
};